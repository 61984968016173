import '../styles/index.scss';
import Vivus from '../../node_modules/vivus';

const container = document.querySelector('html');

const viv =  new Vivus('mySVG', {type: 'sync', duration: 100, animTimingFunction: Vivus.EASE}, function (obj) {
    obj.el.classList.add('finished');
});


//navigation
const nav = document.querySelector('.header');
const navTop = nav.offsetTop;

function stickyNavigation() {
    console.log('navTop = ' + navTop);
    console.log('scrollY = ' + window.scrollY);

    if (window.scrollY >= navTop) {
        // nav offsetHeight = height of nav
        document.body.style.paddingTop = nav.offsetHeight + 'px';
        document.body.classList.add('fixed-nav');
    } else {
        document.body.style.paddingTop = 0;
        document.body.classList.remove('fixed-nav');
    }
}

window.addEventListener('scroll', stickyNavigation);

//scroll To
import { scrollTo } from 'scroll-js';

scrollTo(window, { top: 1 }).then(function() {
    // window has scrolled 500 pixels down the page
});




